import 'core-js/es/map';
import 'core-js/es/set';

import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { render } from 'react-dom';
import App from './App';

const rootNode = document.querySelector('#__DOCUMENTATION_NODE__');

const EmptyPage = () => {
  return (
    <h1>Some Empty Page</h1>
  )
}

if (rootNode) {
  render(
    <Router>
      <Switch>
        <Route component={EmptyPage} exact path="/empty" />
        <Route component={App} exact={false} path="*" />
      </Switch>
    </Router>,
    rootNode
  );
}
