import React, { Component } from 'react';
import { Layout, Row, Col, Input, Button, Tooltip, Table, notification } from 'antd';
import ClipboardJS from 'clipboard';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { dataSource, tableColumns } from './tableSetup';
import { Link } from 'react-router-dom';
import { minifiedLibHost, widgetScriptURLs } from '../../shared/constants/urls';
import { demoWidgetKeys } from '../utils/constants';

import './styles.scss';
import { env } from '../utils';

const { environment, isDevelopment } = env;


function getUrlString() {
  const host =  `${minifiedLibHost[environment]}`;
  const path = isDevelopment ? '/rentgrata.min.js' : '/rentgrata.js';

  return host + path;
}

const { Content } = Layout;
const styles = {
  layout: {
  },
  content: {
    background: '#fff'
  },
  row: {
    flexWrap: 'wrap',
    background: '#fff'
  },
  col: {
    backgroundColor: '#f8f9fa',
    minHeight: '300px',
    border: '1px solid #ffffff',
    padding: '3rem',
  },
  tableCol: {
    padding: '4rem',
    background: '#fff',
    marginBottom: '15px'
  },
  h1: {
    fontSize: '1.75rem',
    marginBottom: '.5rem',
    fontWeight: 500,
    lineHeight: 1.2,
    color: '#000',
  },
  p: {
    fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
    fontSize: '1.2rem',
    fontWeight: 400,
    lineHeight: 1.5,
    color: '#000000',
    textAlign: 'left',
  }
};

const codeStrings = {
  html: `<script src="${getUrlString()}"></script>`,
  js: `
<body>
  <header>My Website</header>

  <script>
    <!-- Before closing body tag -->
    (function (d, s, id, k, r) {
      var js, hjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s); js.id = id; js.async = true;
      js.src = 'https://widget.rentgrata.com/rentgrata.js';
      js.addEventListener('load', function () { window.rentgrata.Widget.render({ widget_key: k, render_as: r }) });
      hjs.parentNode.insertBefore(js, hjs);
    }(document, 'script', 'rentgrata-sdk', 'my-widget-goes-inside-this-single-quote'));
  </script>
</body>
`,
  asBtn: `
<body>
  <header>My Website</header>
  <button onClick={openChatWithWithAResident}>Chat with a Resident</button>

  <script>
    <!-- This will render the widget with a display as none -->
    (function (d, s, id, k, r) {
      var js, hjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s); js.id = id; js.async = true;
      js.src = 'https://widget.rentgrata.com/rentgrata.js';
      js.addEventListener('load', function () { window.rentgrata.Widget.render({ widget_key: k, render_as: r }) });
      hjs.parentNode.insertBefore(js, hjs);
    }(document, 'script', 'rentgrata-sdk', 'my-widget-goes-inside-this-single-quote', 'button'));


    <!-- Attaching an onclick event on a button -->
    function openChatWithWithAResident() {
      <!-- This toggles the state of the widget from display to hide -->
      <!-- Note: calling openWidget when render_as is not button makes no difference -->
      rentgrata.Widget.openWidget();
    }
  </script>
</body>
`,
  relative: `
<body>
  <header>My Website</header>
  <!-- Create and element with an id "rg-widget-relative" -->
  <div id="rg-widget-relative"></div>

  <script>
    <!-- This will render the widget inside the div with id "rg-widget-relative" -->
    <!-- It throws an error if a div with id "rg-widget-relative" is not found -->
    (function (d, s, id, k, r) {
      var js, hjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s); js.id = id; js.async = true;
      js.src = 'https://widget.rentgrata.com/rentgrata.js';
      js.addEventListener('load', function () { window.rentgrata.Widget.render({ widget_key: k, render_as: r }) });
      hjs.parentNode.insertBefore(js, hjs);
    }(document, 'script', 'rentgrata-sdk', 'my-widget-goes-inside-this-single-quote', 'relative'));
  </script>
</body>
`
};

class App extends Component {
  componentDidMount() {
    this.clipboard = new ClipboardJS('.click-to-copy-btn');

    this.clipboard.on('success', (e) => {
      notification.success({
        message: 'Copied!',
        description: 'Successfully copied CDN link',
        duration: 1
      });
      e.clearSelection();
    });

    this.clipboard.on('error', (e) => {
      notification.error({
        message: 'Error!',
        description: 'Unable to copy CDN link',
        duration: 1,
      });
      e.clearSelection();
    });

    (function (d, s, id, k, r) {
      var js, hjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s); js.id = id; js.async = true;
      js.src = widgetScriptURLs[environment]
      js.addEventListener('load', function () { window.rentgrata.Widget.render({ widget_key: k, render_as: r }) });
      hjs.parentNode.insertBefore(js, hjs);
    }(document, 'script', 'rentgrata-sdk', demoWidgetKeys[environment][0], 'fixed'));
  }

  render() {
    return (
      <Layout style={styles.layout}>
        <Content style={styles.content}>
          <Row type="flex" id="intro-wrapper">
            <h1>Rengrata Widget</h1>

            <h2>
              <a href={`${minifiedLibHost[environment]}/demo`} target="_blank">
                View demo here
              </a>
            </h2>

            <Link to="/empty">Link Page Link</Link>
          </Row>

          <Row type="flex" style={styles.row}>
            <Col span={12} style={styles.col}>
              <h3 style={styles.h1}>Installation</h3>
              <p style={styles.p}>
                Include widget JavaScript distribution file via CDN.
              </p>

              <h4>Click button below to copy</h4>
              <Row type="flex" id="click-to-copy-wrapper">
                <Input id="click-to-copy" value={`${getUrlString()}`} readOnly />
                <Tooltip title="click to copy">
                  <Button className="click-to-copy-btn" data-clipboard-target="#click-to-copy" icon="copy" />
                </Tooltip>
              </Row>

            </Col>
            <Col span={12} style={styles.col}>
              <h3 style={styles.h1}>Usage</h3>
              <h4>On any page you'd like the widget to appear:</h4>

              <br />
              <h4>Import the widget js file into the html head</h4>
              <SyntaxHighlighter showLineNumbers language='html' style={dracula}>{codeStrings.html}</SyntaxHighlighter>

              <br />
              <h4>Include this script tag as shown below and pass in a <b>widget_key</b></h4>
              <SyntaxHighlighter showLineNumbers language='javascript' style={dracula}>{codeStrings.js}</SyntaxHighlighter>

              <br />
              <h4>Use as <b>button</b></h4>
              <SyntaxHighlighter showLineNumbers language='javascript' style={dracula}>{codeStrings.asBtn}</SyntaxHighlighter>


              <br />
              <h4>Use as <b>Relative</b></h4>
              <SyntaxHighlighter showLineNumbers language='javascript' style={dracula}>{codeStrings.relative}</SyntaxHighlighter>
            </Col>
          </Row>

          <Row>
            <Col span={24} style={styles.tableCol}>
              <h4>Properties</h4>
              <Table dataSource={dataSource} columns={tableColumns} pagination={false} />
            </Col>
          </Row>
        </Content>
      </Layout>
    )
  }
}

export default App;
