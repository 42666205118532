import React from 'react';

export const dataSource = [
  {
    key: '1',
    property: 'widget_key',
    datatype: 'String',
    required: 'true',
    default: '',
    description: 'Apartment widget key from Rengrata.com',
    enums: '',
  },
  {
    key: '2',
    property: 'render_as',
    datatype: 'String',
    required: 'false',
    default: 'fixed',
    description:
      'Use this option to configure how the widget interact with the page',
    enums: ['fixed', 'relative', 'button'],
  },
  {
    key: '3',
    property: 'position',
    datatype: 'String',
    required: 'false',
    default: 'left',
    description:
      'Use this option to render the widget on the left or right side of the page. (left/right)',
    enums: '',
  },
  {
    key: '4',
    property: 'ignore_url_paths',
    datatype: 'Array',
    required: 'false',
    default: '[]',
    description:
      'List of url patterns widget will not be loaded. These can be url paths or regex patterns',
    enums: '',
  },
  {
    key: '5',
    property: 'bubble',
    datatype: 'Object',
    required: 'false',
    default: '{ count: 3 }',
    description: 'Configuration for widget bubbles',
    enums: '{ count: 3 }',
  },
];

export const tableColumns = [
  {
    title: 'Property',
    dataIndex: 'property',
    key: 'property',
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'Data type',
    dataIndex: 'datatype',
    key: 'datatype',
    render: (text, r) => {
      return (
        <span
          dangerouslySetInnerHTML={{
            __html: `${text}${r.enums ? `: ${r.enums}` : ''}`,
          }}
        />
      );
    },
  },
  {
    title: 'Required',
    dataIndex: 'required',
    key: 'required',
  },
  {
    title: 'Default',
    dataIndex: 'default',
    key: 'default',
  },
];
